<template>
  <div class="accountManagement">
    <a-spin :spinning="spinning">
      <div style="border-bottom: 1px solid #edeff2;background-color: white">
        <div class="moTitle">
          会议管理
          <div><a-button type="primary" @click="addTemplate">+ 创建会议</a-button></div>
        </div>
      </div>
      <div class="list">
        <a-row :gutter="16">
          <a-col  :span="innerWidths > 0 && innerWidths < 1920 ? 5 :4" v-for="(item,index) in dataList" :key="index" >
            <div class="listCol">
             <div @click="openContent(item)">
               <div class="listCol-title">
                 <a-tooltip>
                   <template slot="title">
                     {{item.title}}
                   </template>
                   {{item.title}}
                 </a-tooltip>
               </div>
               <div class="listCol-time">{{item.beginDate}} ～ {{item.endDate.slice(5-10)}}</div>
               <div class="listCol-address">{{item.address}}</div>
               <div class="listCol-progress" v-if="item.allDetailTaskCount"><a-progress :percent="(parseInt((item.haveFinishedCount / item.allDetailTaskCount)*100))" /></div>
               <div class="listCol-progress" v-else><a-progress :percent="0" /></div>
             </div>
              <div class="listCol-info">
                <div>{{item.createdName}}</div>
                <div>{{item.createdTime}} 创建</div>
                <div>
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <admin_con style="font-size: 16px;padding-top: 2px;color: #9c9b9b" type="icon-shenglvehao"/>
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a href="javascript:;" @click="editLive(item)">修改</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a-popconfirm
                            title="是否确认删除?"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="delBtn(item)"
                        >
                          <a href="#">删除</a>
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>

                </div>
              </div>
            </div>
          </a-col>
        </a-row>

        <div style="width: 100%;margin-top: 30px" v-show="dataList.length">
          <div style="display: flex;justify-content: center">
            <a-pagination
                show-quick-jumper
                :pageSize="innerWidths > 0 && innerWidths < 1920 ? 10 : 12"
                :current="page_no"
                :total="total"
                @change="paginationChange" />
          </div>
          <div style="height: 10px"></div>
        </div>

        <div v-if="dataList.length === 0"><NullDataPrompt message="您还没有会议呢 ~"/></div>
      </div>
    </a-spin>
<!--    新增   -->
    <a-drawer
        :get-container="false"
        @close="add_cancel"
        :width="660"
        :body-style="{ paddingBottom: '80px' }"
        :visible="add_visible"
        :title="title">
      <a-form-model
          ref="refForm"
          :model="add_Form"
          :rules="addFormRules"
          :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }"
      >
        <a-form-model-item  prop="name" label="会议名称">
          <a-input v-model="add_Form.name" style="width: 300px;" placeholder="请输入"/>
          <span style="color: #a1a1a1;font-size: 12px;">注：会议名称最长不能超过60个汉字</span>
        </a-form-model-item>
        <a-form-model-item  prop="time" label="会议日期">
          <a-range-picker v-model="add_Form.time" style="width: 300px" @change="DateChange"/>
        </a-form-model-item>
        <a-form-model-item  prop="address">
          <template slot="label">
              <span>会议地点/描述
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{tooltipAddress}}</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </span>
          </template>
          <a-input style="width: 300px" v-model="add_Form.address" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item  prop="conventionHead" label="负责人">
          <a-select
              allowClear
              show-search
              v-model="add_Form.conventionHead"
              placeholder="请选择"
              style="width: 300px;margin-bottom: 15px"
              optionFilterProp="label"
          >
          <span slot="notFoundContent">
             <div style="text-align: center">
               <admin_con style="font-size: 40px" type="icon-kong-hezi"/>
                 <br/>
               <span style="font-size: 12px">暂无数据,请去执行人员管理中维护</span>
             </div>
          </span>
            <a-select-option v-for="item in principal2Data" :key="item.id" :value="item.id" :label="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
        <a-button  @click="add_cancel">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="submitSave">
          确认
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>

import {admin_icon} from "@/utils/myIcon";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
import moment from "moment";
import {
  CaseLiveNew,
  CaseLiveNewAffairs,
  CaseLiveNewAffairsKF,
  CaseLiveNewKF,
  deleteConvention, deleteConventionAffairs, putSwitch
} from "@/service/MedicalConference";
import NullDataPrompt from "@/components/NullDataPrompt";
import {
  postConvention,
  postConventionAffairs,
  postConventionAffairsKf,
  postConventionKf
} from "@/service/medicalConference_api";
import {getMemberList} from "@/service/participants";

export default {
  components:{admin_con: admin_icon,NullDataPrompt},
  data() {
    return {
      principal2Data:[],
      fzrTmId: undefined,
      title:'',
      dataList:[],
      total:0,
      page_no:1,
      innerWidths:null,
      spinning:false,
      add_visible:false,
      add_Form:{
        name:'',
        time:null,
        address:'',
      },
      addFormRules:{
        name: [{ required: true, message: '请输入', trigger: 'blur' },{ min: 1, max: 60, message: '会议名称最长不能超过60个汉字', trigger: 'blur' },],
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        conventionHead: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      tooltipAddress:'显示在转发之后的消息摘要处',
      userId:null,
      isKefu:null,
    }
  },
  created() {
    this.getpersonInCharge()
    this.innerWidths = window.innerWidth; //屏幕宽度
    const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    this.userId = userInfo.id;
    this.isKefu = userInfo.isKefu;

    this.getLiveList();
  },
  mounted(){

  },
   methods:{
     // 负责人
     async  getpersonInCharge(){
       let data = {
         name:'',
         pageNo:1,
         pageSize:9999,
       }
       const response = await getMemberList('insider',data)
       if(response.code === 0){
         this.principal2Data = response.data; //负责人
       }
     },
     paginationChange(page_no,page_size){
       this.page_no = page_no;
       this.getLiveList()
     },
     async delBtn(row) {
       const response = await deleteConvention(row.conferenceCode)
       if (response.code === 200) {
         this.$message.success("删除成功！");
         //删除成功刷新列表
         await this.getLiveList();
       } else {
         this.$message.warning(response.message)
       }
     },
     editLive(row){
       this.title = '修改会议';
       this.add_Form = {
         name:row.title,
         address:row.address,
         beginDate:row.beginDate,
         endDate:row.endDate,
         conventionHead:row.conventionHead,
         coverPics:row.coverPics,
         homeCoverImage:row.homeCoverImage,
         relayImg:row.relayImg,
         conferenceCode:row.conferenceCode,
         domainCode:row.domainCode,
         time:[moment(row.beginDate, 'YYYY-MM-DD'), moment(row.beginDate, 'YYYY-MM-DD')]
       }
       this.add_visible = true;
     },
     //获取会议列表
     //客服和用户接口不一致
     async getLiveList() {
       this.spinning = true;
       let byId = this.$route.query.memberId;
       let data;
       let response;
       if(this.isKefu){ //客服
         data = {
           // title:this.titleData,
           // columId:this.columId,
           // liveStatus:this.liveStatus,
           // dateBegin:this.year[0],
           // dateEnd:this.year[1],
           page_no:this.page_no,
           byId:byId, //用户id
           page_size:this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
         }
         response = await CaseLiveNewAffairsKF(data)
       }else if(!this.isKefu){ //用户
         data = {
           // title:this.titleData,
           // columId:this.columId,
           // liveStatus:this.liveStatus,
           // dateBegin:this.year[0],
           // dateEnd:this.year[1],
           page_no:this.page_no,
           page_size:this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
         }
         response = await CaseLiveNewAffairs(data)
       }
       if(response.code === 0){
         this.dataList = response.data.rows;
         this.total = response.data.count;
       }else {
         this.$message.warning(response.message)
       }
       this.spinning = false;

     },
     submitSave() {
       this.$refs.refForm.validate((valid) => {
         if (valid) {
           let form = this.add_Form;
           let data;
           if(this.title === '创建会议'){
             if(this.isKefu){
               //  客服
               data = {
                 address: form.address, //会议地址
                 beginDate: moment(form.time[0]).format("YYYY-MM-DD"), //会议日期-开始
                 endDate: moment(form.time[1]).format("YYYY-MM-DD"), //会议日期-结束
                 domainCode: "yichimeeting", //写死传过来
                 title: form.name, //会议标题

                 coverPics: ["https://cos.yichimeeting.com/images_convention/4438eb00-70fc-4084-8dab-8b9302dae545.png"], //封面图
                 homeCoverImage: 'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/88eda9b5-6450-4991-9605-7d4ddebd1244.png', //背景图
                 relayImg: '', //转发封面图
                 tempUrl: "/conference", //选择模板的链接
                 conventionHead: form.conventionHead,
                 isKfCreated:1, //客服创建
                 createdKfId:this.userId, //客服id
                 byId:this.$route.query.memberId //客服创建会议用户id
               };
               this.post_conventionKf(data);
             }
             else if(!this.isKefu){
               //  用户
               data = {
                 address: form.address, //会议地址
                 beginDate: moment(form.time[0]).format("YYYY-MM-DD"), //会议日期-开始
                 endDate: moment(form.time[1]).format("YYYY-MM-DD"), //会议日期-结束
                 domainCode: "yichimeeting", //写死传过来
                 title: form.name, //会议标题
                 conventionHead: form.conventionHead,
                 coverPics: ["https://cos.yichimeeting.com/images_convention/4438eb00-70fc-4084-8dab-8b9302dae545.png"], //封面图
                 homeCoverImage: 'https://yichi-dev-1252497858.cos.ap-guangzhou.myqcloud.com/images_convention/88eda9b5-6450-4991-9605-7d4ddebd1244.png', //背景图
                 relayImg: '', //转发封面图
                 tempUrl: "/conference", //选择模板的链接

                 byId:this.userId //用户自己id
               };
               this.post_convention(data);
             }
           }else if(this.title === '修改会议'){
             data = {
               "conferenceCode":form.conferenceCode,//会议编码
               "address": form.address,//会议地址
               "beginDate": moment(form.time[0]).format("YYYY-MM-DD"), //会议日期-开始
               "endDate": moment(form.time[1]).format("YYYY-MM-DD"), //会议日期-结束
               "title": form.name,//会议标题
               conventionHead: form.conventionHead,
             }
             this.putLive(data);
           }
         } else {
           return false;
         }
       });
     },
     async putLive(data) {
       const response = await putSwitch(data)
       if (response.code === 0) {
         this.$message.success("修改成功！")
         this.add_cancel();
         //删除成功刷新列表
         await this.getLiveList();
       } else {
         this.$message.warning(response.message)
       }
     },
     //新增会议列表 -- 客服
     async post_conventionKf(data) {
       const response = await postConventionAffairsKf(data);
       if (response.code === 0) {
         this.$message.success("新增成功~");
         this.add_cancel();
         //删除成功刷新列表
         await this.getLiveList();
       } else {
         this.$message.warning(response.message);
       }
     },

     //新增会议列表 -- 用户
     async post_convention(data) {
       const response = await postConventionAffairs(data);
       if (response.code === 0) {
         this.$message.success("新增成功~");
         this.add_cancel();
         //删除成功刷新列表
         await this.getLiveList();
       } else {
         this.$message.warning(response.message);
       }
     },
     add_cancel(){
       //  关闭对话框
       this.add_visible = false;
       this.$refs.refForm.resetFields();
     },
     DateChange(date, dateString) {
       this.add_Form.time = null;
       this.add_Form.time = dateString;
     },
     addTemplate(){
       this.title = '创建会议';
       this.add_visible = true;
     },
     openContent(item){
       this.$store.dispatch("liveInfoList",item); // 会议详情
       this.$router.push({path:'/meetingManagementClub',query:{code:item.conferenceCode,id:item.id}})
       this.$store.dispatch("isShowNav", false);
     }
   }
}
</script>

<style scoped lang="scss">

.ant-col-5 {
  width: 20%;
}
.ant-col-4 {
  width: 16.66666666%;
}

.accountManagement {
  border: 10px solid #EDEFF2;
  width: 100%;
  background-color: white;
 }
.moTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin: auto;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: $topTitleColor;
}
.list{
  width: 100%;
  padding: 20px;
  background-color: white;
  .listCol{
    border: 1px solid #E9E9E9;
    min-height: 150px;
    padding: 10px 10px 5px 10px;
    margin-bottom: 16px;
    .listCol-title{
      color: #333333;
      font-weight: bold;
      font-size: 16px;
      height: 45px;
      //line-height: 45px;
      width: 100%;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      padding-bottom: 5px;
      cursor: pointer;
    }
    .listCol-time{
      color: #929292;
      padding-bottom: 5px;
      cursor: pointer;
    }
    .listCol-address{
      color: #929292;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .listCol-progress{
      border-top: 1px solid #E9E9E9;
      padding-top: 5px;
      cursor: pointer;
    }
    .listCol-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #929292;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>
